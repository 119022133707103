export const defaultLoadingMessage = 'loading...'

export const greetings = [
	'Ahoy',
	'Meow',
	'What\'s Shakin\'',
	'Hello',
	'Hey',
	'Welcome',
	'Hi',
	'\'Ello',
	'Greetings',
	'Hi There',
	'Bonjour',
	'Hola',
	'Ciao',
	'Olá',
	'Kia Ora',
	'G’day',
	'Geia',
	'Zdravo',
	'Privet',
	'Nǐ hǎo',
	'Namaste',
	'Merhaba',
	'Kon’nichiwa',
	'Anyeong Haseyo',
	'Ahoj',
	'Guten tag',
	'Hallo',
	'Cześć',
	'S̄wạs̄dī',
	'Cheers',
	'Szia',
	'Ahoj',
	'Salve',
	'Talofa',
	'Haló',
	'Hola',
	'Hallo',
	'Mirë dita',
	'Marhabaa',
	'salam',
	'Салам',
	'ታዲያስ',
	'مرحبا',
	'Kaixo',
	'নমস্কার',
	'Zdravo',
	'Oi',
	'Demat',
	'Здравейте',
	'Moni',
	'Hej',
	'Tere',
	'Salaam',
	'Bula',
	'Kamusta',
	'Aloha',
	'Halo',
	'Hai',
	'안녕',
	'こんにちは',
	'你好',
	'Chào bạn',
	'Helo',
	'Hallá',
]
